import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

class CV extends Component {
  renderWorkEntry(entry) {
    const { company, title, startDate, endDate, clients, summary } = entry;
    return (
      <div className="entry" key={`${company}-${title}`}>
        <hgroup className="columns is-gapless">
          <div className="column">
            <h2 className="title is-6">{company}</h2>
            <h2 className="subtitle is-6 has-text-grey has-text-weight-normal">
              {title}
            </h2>
          </div>
          <aside className="column is-narrow is-size-7">
            {startDate} - {endDate}
          </aside>
        </hgroup>
        {clients && <p className="is-size-14">Clients: {clients.join(', ')}</p>}
        {summary && summary.__html && <div dangerouslySetInnerHTML={summary} />}
      </div>
    );
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            file(relativePath: { eq: "laura.png" }) {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        `}
        render={(data) => (
          <section className="section">
            <div className="container">
              <h1 className="title has-text-centered is-hidden-print">CV</h1>
              <div className="columns">
                <div className="column is-narrow has-text-centered">
                  <h1 className="title">
                    <Img
                      fadeIn={false}
                      fluid={data.file.childImageSharp.fluid}
                      style={{ borderRadius: '50%' }}
                    />
                  </h1>
                  <h1 className="title is-4 is-uppercase">Laura Calabro</h1>
                  <h1 className="title is-6 is-uppercase">Software Engineer</h1>
                  <hr />
                  <p>15 Mohawk Ave.</p>
                  <p>Rockaway, NJ 07866</p>
                  <p>973-747-8168</p>
                  <p>
                    <a
                      href="mailto:laura.t.calabro@gmail.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      laura.t.calabro@gmail.com
                    </a>
                  </p>
                  <hr />
                  <h1 className="title is-6 is-uppercase">Skills</h1>
                  <ul>
                    <li>Node</li>
                    <li>AWS</li>
                    <li>JavaScript / TypeScript</li>
                    <li>Java / Kotlin</li>
                    <li>React</li>
                    <li>HTML &amp; CSS</li>
                    <li>Docker</li>
                    <li>...</li>
                  </ul>
                </div>
                <div className="column is-offset-1 content">
                  <h1 className="title is-6 is-uppercase">Summary</h1>
                  <p>
                    Experienced full stack developer with a demonstrated history
                    as a consultant having worked in the finance, health care,
                    travel and entertainment industries. Skilled in back end
                    technologies such as Node.js and Java as well as several
                    front end technologies including React. Enjoys the challenge
                    of ramping up with new technologies in order to hit the
                    ground running on a project.
                  </p>
                  <hr />
                  <h1 className="title is-6 is-uppercase">Work History</h1>
                  <div className="work-experience">
                    {[
                      {
                        company: 'AWS',
                        title: 'Software Development Engineer II',
                        startDate: '2019',
                        endDate: 'present',
                        summary: {
                          __html: `
                            <p>Working as an engineer on an internal team at AWS. Previously worked as an engineer on <a href="https://aws.amazon.com/data-exchange/" target="_blank">AWS Data Exchange</a>.</p>
                          `,
                        },
                      },
                      {
                        company: 'Isobar',
                        title: 'Interactive Architect',
                        startDate: '2018',
                        endDate: '2019',
                        clients: ['National Car Rental', 'IntegraMed'],
                        summary: {
                          __html: `
                            <p>
                              Promoted to Interactive Architect after demonstrating an ability to architect and own an application layer as well as effectively work on the full stack.
                            </p>
                            <p>
                              Gained some experience with AWS, Docker, and Jenkins pipelines. Began working with Serverless Framework to run AWS Lambdas.
                            </p>
                          `,
                        },
                      },
                      {
                        company: 'Isobar',
                        title: 'Lead Interactive Developer',
                        startDate: '2015',
                        endDate: '2018',
                        clients: ['IntegraMed', 'Royal Caribbean'],
                        summary: {
                          __html: `
                            <p>
                              Promoted to Lead Interactive Developer after showing proficiency in multiple front end and back end technologies. Worked on a short engagement with 
                              Royal Caribbean Cruise Lines as a full stack developer making updates to both the front end and back end application layers.
                            </p>
                            <p>
                              Started on a project for a client in the health care industry initially working on a Mule ESB API layer. Gained some working knowledge of the framework. 
                              Switched over to being the sole developer of a Node.js API layer responsible for aggregating data from the client's service layer for web application 
                              consumption. Helped the client work on their Mule ESB service layer and authored a mechanism to programmatically generate JSON schemas from the Java 
                              classes during a maven build.
                            </p>
                            <p>
                              Began working with React and Redux when I had to make updates in the web application that consumed the Node.js API layer.
                            </p>
                          `,
                        },
                      },
                      {
                        company: 'Isobar',
                        title: 'Senior Interactive Developer',
                        startDate: '2013',
                        endDate: '2015',
                        clients: ['Royal Caribbean'],
                        summary: {
                          __html: `
                            <p>
                              Earned the promotion to Senior Interactive Developer after demonstrating the ability to quickly ramp up on projects and effectively use newly learned 
                              technologies. Worked for one year on a project for Royal Caribbean Cruise Lines as a front end developer within a large development team. 
                              Leveraged HTML/JSP, CSS/SCSS, JavaScript, and Handlebars templates to create a responsive web application for customers to personalize their cruise experience. 
                              Gained some exposure to Spring MVC which backed the web application. 
                            </p>
                            <p>
                              Spent the following year working on another project for Royal Caribbean Cruise Lines staffed as a back end developer working on a Spring MVC application 
                              data layer between the client's service layer and the front end. Worked daily with Maven and was able to debug build issues.
                            </p>
                          `,
                        },
                      },
                      {
                        company: 'Isobar',
                        title: 'Interactive Developer',
                        startDate: '2012',
                        endDate: '2013',
                        clients: ['Bloomberg', 'MSG', 'HBO', 'Ricki Lake'],
                        summary: {
                          __html: `
                            <p>
                              Hired as an entry-level consultant in front end development. Work primarily consisted of creating responsive solutions for our client's needs with 
                              HTML, CSS/SCSS, and JavaScript. Also spent a number of months on a project where I gained a working knowledge of Angular.js and Ruby on Rails. 
                              Authored an internal Isobar web application using Ruby on Rails, Haml, and JavaScript which is still being used to this day.
                            </p>
                          `,
                        },
                      },
                      {
                        company: 'WebMD',
                        title: 'Front End Web Developer',
                        startDate: '2010',
                        endDate: '2012',
                        clients: ['Various Pharmaceutical Companies'],
                        summary: {
                          __html: `
                            <p>
                              Worked as a Front End Developer for the Web Services team of WebMD's Professional Portal, Medscape, making multi page microsites for pharmaceutical companies. 
                              Work focused primarily on developing cross-browser/cross-platform experiences with HTML, JSP, CSS, and JavaScript.
                            </p>
                          `,
                        },
                      },
                      {
                        company: 'FYI Business Solutions',
                        title: 'Consultant',
                        startDate: '2010',
                        endDate: '2010',
                        clients: ['WebMD'],
                        summary: {
                          __html: `
                            <p>Contracted out as a front end development consultant.</p>
                          `,
                        },
                      },
                      {
                        company: 'Freelance',
                        startDate: '2007',
                        endDate: '2019',
                        summary: {
                          __html: `
                            <p>
                              Began creating websites in 2007. Primarily self-taught initially starting out with basic HTML, CSS, and JavaScript. 
                              Worked on some projects involving different CMSs such as: WordPress, Drupal, and Joomla.
                            </p>
                          `,
                        },
                      },
                    ].map(this.renderWorkEntry)}
                  </div>
                  <hr />
                  <h1 className="title is-6 is-uppercase">Education</h1>
                  <div className="entry">
                    <hgroup className="columns is-gapless">
                      <div className="column">
                        <h2 className="title is-6">Seton Hall University</h2>
                        <h2 className="subtitle is-6 has-text-grey has-text-weight-normal">
                          BS Biology
                        </h2>
                      </div>
                      <aside className="column is-narrow is-size-7">
                        2004 - 2009
                      </aside>
                    </hgroup>
                    <p>
                      Started off in a doctorate program for Physical Therapy
                      until early on in my junior year when my boss at the
                      clinic I was working at said, "Laura, can you build us a
                      website?" and I of course said "Yes" not knowing even
                      where to begin. After launching that first website, it
                      became clear to me what I wanted to do, and it wasn't
                      physical therapy.
                    </p>
                    <p>
                      I graduated with a degree in Biology and minors in
                      Computer Science and Mathematics.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      />
    );
  }
}

export default CV;
